<template>
  <div class="LOGIN">
    <div class="login_center">
      <div class="reacte">
        <img src="@/assets/chilun_xj.png" />
        <p class="cre">创建你的智荟网账号</p>
        <p>
          注册成功后，你可以享受 专业内容知识服务 智荟网 旗下所有产品和服务
        </p>
        <div class="native_left">
          <el-link
            @click="native_home"
            target="_blank"
            :underline="false"
            style="color:#CBCBCC"
            >知识头条 </el-link
          >|
          <el-link
            @click="native_question"
            target="_blank"
            style="color:#CBCBCC"
            :underline="false"
            >智能问答</el-link
          >|
          <el-link
            @click="native_resource"
            target="_blank"
            style="color:#CBCBCC"
            :underline="false"
            >资源交易</el-link
          >|
          <el-link
            @click="native_source"
            target="_blank"
            style="color:#CBCBCC"
            :underline="false"
            >跨库检索</el-link
          >
        </div>
      </div>
      <div class="ant">
        <el-menu class="el-menu-demo" mode="horizontal">
          <!-- <el-menu-item index="1">
            <img
              src="@/assets/icon/20190506 登录WX Z.png"
            />微信登陆</el-menu-item
          > -->
          <el-menu-item index="1" center>
            <img
              src="@/assets/icon/手机.png"
            />昵称/手机号/邮箱登录</el-menu-item
          >
        </el-menu>
        <div class="input">
          <el-input
            v-model="loginForm.loginName"
            placeholder="昵称/手机号/邮箱"
          ></el-input>
          <div class="nv">
            <el-input
              v-model="loginForm.passWord"
              show-password
              placeholder="密码"
              @keyup.enter.native="login"
            ></el-input>
            <el-link type="primary" :underline="false" @click="goChangeCode"
              >忘记密码</el-link
            >
          </div>
          <el-button @click="login" round>登录</el-button>
        </div>
        <div class="bottom">
          <div class="bottom_left">
            <label>
            <!-- <el-checkbox
              v-model="checked"
              @keyup.enter.native="login()"
            ></el-checkbox
            > -->
             <input
             class="checkbox"
              type="checkbox"
              v-model="checked"
              @keyup.enter.native="login()"
            />
            同意并遵守
             </label>
            <el-link
              type="primary"
              :underline="false"
              slot="reference"
              @click="dialogVisible = true"
              >服务条款</el-link
            >
          </div>
          <div class="bottom_right">
            还没有注册？<el-link
              type="primary"
              @click="goRegister"
              :underline="false"
              >去注册</el-link
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="智荟网服务条款"
      :visible.sync="dialogVisible"
      width="90%"
      height="100%"
      :modal-append-to-body="false"
      :before-close="handleClose"
    >
      <div>
        <h3>1.1使用规则</h3>
        <p>
          1.用户注册成功后，智荟网将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。
        </p>
        <p>
          2.用户须对在智荟网的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；否则智荟网有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。
        </p>
        <p>
          3.用户直接或通过各类方式间接使用智荟网服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用智荟网所提供的全部服务。
        </p>
        <p>
          4.智荟网是一个众智化服务的平台，用户通过智荟网发表的信息为公开的信息，其他第三方均可以通过智荟网获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在智荟网上进行发表。
        </p>
        <p>
          5.用户承诺不得以任何方式利用智荟网直接或间接从事违反中国法律以及社会公德的行为，智荟网有权对违反上述承诺的内容予以删除。
        </p>
        <p>
          6.用户不得利用智荟网服务制作、上载、复制、发布、传播或者转载如下内容：
        </p>
        <p>反对宪法所确定的基本原则的；</p>
        <p>
          危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        </p>
        <p>损害国家荣誉和利益的；</p>
        <p>煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>
          侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；
        </p>
        <p>破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>含有法律、行政法规禁止的其他内容的信息。</p>

        <p>
          7.智荟网有权对用户使用智荟网的情况进行审查和监督，如用户在使用智荟网时违反任何上述规定，智荟网或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用智荟网的权利）以减轻用户不当行为造成的影响。
        </p>

        <h3>2.2知识产权</h3>
        智荟网尊重和鼓励智荟网用户创作的内容，认识到保护知识产权对智荟网生存与发展的重要性，承诺将保护知识产权作为智荟网运营的基本原则之一。用户在智荟网上发表的全部原创内容（包括但不仅限于回答、文章和评论），著作权均归用户本人所有。用户可授权第三方以任何方式使用。

        <h3>2.3知识币交易</h3>
        <p>
          1.知识币是专供智荟网用户在智荟网平台使用的虚拟币，知识币可用于智荟网平台上购买和消费内容产品。除此之外，不得用于其他用途。
        </p>
        <p>
          2.如果用户使用他人代充或以其他违规方式充值造成其他用户或第三方权益受损时，不得因此要求智荟网作任何补偿或赔偿，智荟网保留随时冻结其帐户余额、暂停或终止其使用各项充值服务及禁用其帐户的权利。如智荟网有理由相信用户的知识币帐户或使用情况涉及违规充值、作弊或异常状况，智荟网有权拒绝该用户继续使用知识币，并按本协议采取冻结、禁用帐户等相关措施。
        </p>
        <p>
          3.知识币和人民币的兑换比例以用户购买知识币的服务页面显示为准。智荟网有权根据运营情况随时变更兑换比例，并在用户购买知识币的服务页面显示。
        </p>
        <p>
          4.知识币一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。用户间交易知识币构成对本协议的违反，智荟网有权不通知用户而采取适当措施，以确保智荟网不为违规用户提供知识币交易的平台服务。
        </p>
        <p>5.智荟网支持知识币提现功能。</p>
        <h3>2.4免责声明</h3>
        <p>
          1.智荟网不能对用户发表的回答或评论的正确性进行保证。用户在智荟发表的内容仅表明其个人的立场和观点，并不代表智荟的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。智荟不承担任何法律及连带责任。
        </p>
        <p>
          2.智荟不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证。
        </p>
        <p>
          3.对于因不可抗力或智荟不能控制的原因造成的网络服务中断或其它缺陷，智荟不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { DELETE, QUERY, INSERT, UPDATE } from "@/services/dao.js";
import { integralAddOne } from "@/js/common";
import axios from "axios";
export default {
  data() {
    return {
      activeIndex: "1",
      loginForm: {
        loginName: "",
        passWord: "",
      },
      checked: false,
      dialogVisible: false,
      test: "1",
    };
  },
  created() {
    // console.log("原字符：", this.test);
    // console.log("md5: ", this.$md5(this.test));
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    ...mapMutations(["changeLoginUser"]),

    async login() {
      let _this = this;
      if (this.loginForm.loginName === "" || this.loginForm.passWord === "") {
        this.$message({
          message: "账号或密码不能为空",
          type: "warning",
        });
      } else {
        if (this.checked == true) {
          axios
            .post(this.api.LoginURL.concat("/user/login"), {
              loginName: _this.loginForm.loginName,
              passWord: this.$md5(_this.loginForm.passWord),
              // passWord: _this.loginForm.passWord,
            })
            .then((res) => {
              console.log("res.data: ", res.data);
              _this.userToken = res.data.data.token;
              // 将用户token保存到vuex中
              _this.changeLogin({ Authorization: _this.userToken });
              _this.changeLoginUser({ userinfo: res.data.data.user });

              this.$message({
                message: "登录成功",
                type: "success",
                duration: "1000",
              });

              _this.$router.push("/home");
              //////////该用户登陆积分加1////////////
              let user = this.$store.state.userinfo.id;
              integralAddOne(user);
            })
            .catch((error) => {
              this.$message({
                message: "账号或密码错误",
                type: "warning",
                duration: "1000",
              });

              console.log(error);
            });
        } else {
          this.$message({
            message: "请勾选服务条款",
            type: "warning",
            duration: "1000",
          });
        }
      }
    },
    native_home() {
      this.$router.push({ name: "index", params: { userId: 123 } });
    },
    native_resource() {
      this.$router.push({ name: "Resource", params: { userId: 123 } });
    },
    native_question() {
      this.$router.push({
        path: "/qaIndex",
      });
    },
    native_source() {
      this.$router.push({ name: "Detect", params: { userId: 123 } });
    },
    goRegister() {
      this.$router.push({ path: "/register" });
    },
    // 去改密码
    goChangeCode() {
      this.$router.push({ path: "/change_code" });
    },
  },
};
</script>
<style scoped>
.LOGIN {
  width: 100%;
  height: 100%;
  background-image: url(~@/assets/img/Vol_006_SE182.png);
  background-size: 100% 100%;
  overflow: hidden;
  position: absolute;
}
.login_center {
  /* margin: 161px 153px 178px 143px; */
  width: 80%;
  /* height: 80%; */
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login_center::after {
  display: block;
  content: "";
  clear: both;
}
.reacte {
  width: 458px;
  height: 460px;
  background-color: #1b2343;
  color: #ffffff;
  float: left;
  text-align: center;
}
.reacte img {
  width: 70px;
  margin-top: 139px;
}
.cre {
  font-size: 24px;
}
.ant {
  width: 600px;
  height: 460px;
  background-color: #ffffff;
  float: left;
  position: relative;
}
.el-menu-item {
  text-align: center;
  width: 100%;
}
.el-menu-item img {
  width: 30px;
  margin-top: -5px;
  margin-right: 6px;
}
.input {
  margin: 130px 0px 0 190px;
}
.nv {
  overflow: hidden;
}
.nv .el-link {
  line-height: 40px;
}
.el-button {
  width: 210px;
  background-color: #1b2343;
  color: #ffffff;
}
.bottom {
  width: 100%;
  position: absolute;
  bottom: 10px;
}
.bottom_left {
  float: left;
  width: 60%;
  margin-left: 5%;
}
.el-checkbox__inner {
  border-radius: 999px;
}
.bottom .el-link {
  margin-bottom: 3px;
}
/*样式重构*/
.el-menu-demo >>> .el-menu-item,
.is-active {
  border-bottom: 0;
  color: #303133;
}
.ant .el-menu-item {
  cursor: default;
}
.el-dialog__wrapper >>> .el-dialog__header {
  padding: 20px 20px 10px;
  text-align: center;
  font-weight: 600;
  /* font-size: 26px; */
}
.el-dialog__wrapper >>> .el-dialog__title {
  line-height: 24px;
  font-size: 20px;
  color: #303133;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  height: 360px !important;
  overflow-y: scroll;
}
</style>
